*,
html,
body {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

ul {
  margin: 0;
}

a {
  color: #4a6684;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

label {
  font-weight: 500;
}

label.error {
  display: none;
}

.p-a-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-x-h {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pl-1 {
  padding-left: 1rem !important;
}

.m-h {
  margin: 0.5rem;
}

.m-1 {
  margin: 1rem;
}

.mt-1,
.m-t-1 {
  margin-top: 1rem !important;
}

.mt-2,
.m-t-2 {
  margin-top: 2rem !important;
}

.mb-2,
.m-b-2 {
  margin-bottom: 2rem !important;
}

.mb-1,
.m-b-1 {
  margin-bottom: 1rem !important;
}

.mb-h {
  margin-bottom: 0.5rem !important;
}

.mr-h {
  margin-right: 0.5rem;
}

.ml-h {
  margin-left: 0.5rem;
}

.align-center {
  align-self: center;
  align-items: center !important;
}

body {
  font-size: 13px;
  color: #111111;
  padding-left: 260px;
}

.login {
  background: #ffffff;
  padding-left: 0px;
}

.login .form-sec {
  padding: 20px;
}

.login .title-sec {
  left: 0;
  width: 100% !important;
}

.logo a {
  color: #111111;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 3px;
  display: flex;
  padding: 30px 0px;
}

.logo a img {
  width: 34px;
}

.logo a * {
  align-self: center;
}

.main {
  display: flex;
}

.lg-sec {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bg-sec {
  background: #ffffff url(images/login-bg.jpg) no-repeat center;
  background-size: contain;
  position: relative;
}

.brand-title {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 2;
}

.brand-title h1 {
  font-size: 42px;
  font-weight: 300;
  margin-bottom: 6px;
}

.brand-title p {
  font-size: 16px;
  font-weight: 400;
  opacity: 0.8;
}

/* Login Form */
.lg-form {
  max-width: 350px;
  align-self: center;
}

.lg-form h1 {
  font-size: 32px;
  font-weight: 600;
}

.lg-form h2 {
  font-size: 28px;
}

.lg-form h3 {
  font-size: 13px;
  font-weight: normal;
  margin-top: 16px;
  opacity: 0.7;
  line-height: 20px;
}

.error {
  border-bottom: 1px solid #f00 !important;
}

.font-size-12,
.font-size-12 *,
.font-size-12 input {
  font-size: 12px !important;
}

.font-size-13,
.font-size-13 *,
.font-size-13 input {
  font-size: 13px !important;
}

.font-size-14,
.font-size-14 *,
.font-size-14 input {
  font-size: 14px !important;
}

.font-size-15,
.font-size-15 *,
.font-size-15 input {
  font-size: 15px !important;
}

.font-size-16,
.font-size-16 *,
.font-size-16 input {
  font-size: 16px !important;
}

.font-size-20,
.font-size-20 *,
.font-size-20 input {
  font-size: 20px !important;
}

.search-form input {
  padding: 11px 14px;
}

.form-control {
  height: auto !important;
}

.datePicker {
  margin: 0 10px;
}

button {
  font-size: 14px !important;
}

.white-color:hover {
  color: #ffffff !important;
}

.btn-success {
  white-space: inherit !important;
}

.btn-pan {
  margin: 0 20px !important;
}

.cs-btn {
  color: #ffffff;
  border: 0;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  padding: 6px 15px;
  position: relative;
  justify-content: center;
  width: 100%;
  transition: all 0.5s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.cs-btn,
.btn-primary,
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background: -webkit-linear-gradient(left, #539b00, #519603);
  background: -moz-linear-gradient(left, #539b00, #519603);
  background: linear-gradient(to right, #539b00, #519603) !important;
  border: none !important;
}

.cs-btn.btn-sm {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
}

.cs-btn:hover,
.cs-btn:focus,
.cs-btn:active:focus {
  color: #ffffff;
  background: -webkit-linear-gradient(left, #539b00, #4caf50);
  background: -moz-linear-gradient(left, #539b00, #4caf50);
  background: linear-gradient(to right, #539b00, #4caf50);
}

.btn-primary,
.btn-secondary {
  font-size: 13px !important;
}

.btn-secondary {
  width: auto !important;
  border: 1px solid #cccccc !important;
  background: #fff !important;
  color: #111111 !important;
  box-shadow: 0 3px 2px rgb(90 160 10 / 12%) !important;
}

.btn-primary svg,
.btn-secondary svg {
  font-size: 20px !important;
}

.btn-default {
  background-color: transparent !important;
  color: #000000 !important;
  box-shadow: none !important;
}

.copyrights {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  font-size: 12px;
  opacity: 0.8;
  box-sizing: border-box;
}

.copyrights a:first-child {
  margin-right: 5px;
}

/* OTP Section */
.otp-sec {
  display: flex;
}

.otp-sec div {
  margin: 2px;
}

.otp-sec div:first-child {
  margin-left: 0;
}

.otp-sec div:last-child {
  margin-right: 0;
}

.otp-sec input {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: right;
  align-self: end;
}

.left-menu {
  width: 100%;
  max-width: 320px;
  height: 100vh;
  background-color: #fff;
  padding: 5px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  position: fixed;
  top: 0;
  z-index: 9;
}

.left-menu nav span {
  font-size: 13px;
}

.left-menu nav a {
  color: #000000;
}

.left-menu nav a.active {
  color: #ffffff;
}

.left-menu h3 {
  padding-left: 15px;
  margin-top: 20px;
}

.left-menu li a.active {
  background: linear-gradient(to right, #8bc34a, #4caf50);
  color: #ffffff;
}

.left-menu-inner {
  height: calc(100vh - 190px);
  overflow: auto;
}

.avatar-sec {
  margin-bottom: 15px;
  padding: 0 15px;
}

.profile-pic {
  height: 80px !important;
  width: 80px !important;
  margin-right: 10px;
  border: 1px solid #e3e3e3;
}

.avatar-sec h2 {
  margin: 15px 0 0px;
}

.list-items li * {
  font-size: 14px !important;
}

.cs-form {
  padding: 10px;
}

.cs-form *:not(svg),
.MuiMenu-list * {
  font-size: 14px !important;
}

.cs-form label {
  color: #111111 !important;
}

.cs-form h2 {
  font-size: 24px !important;
  margin-bottom: 40px !important;
}

.lg-sec .cs-form {
  padding: 0;
}

/* .page-title {
  font-size: 24px !important;
} */

.prize-tag span {
  display: inline-block;
}

.imgBox {
  display: inline-block;
  padding: 6px;
  margin: 10px 10px 10px 0;
}

input[type="hidden"] + fieldset {
  display: none;
}

.img-sec img {
  max-width: 100%;
  border-radius: 6px;
}

.back-btn {
  min-width: 30px !important;
  border: 1px solid #cccccc !important;
  color: #666666 !important;
  padding: 0 !important;
  margin-right: 10px !important;
}

.back-btn svg {
  width: 24px;
  height: 30px;
}

.plan-btn {
  color: #000000 !important;
}

.freq-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
}

.freq-items-sec {
  white-space: nowrap;
  display: block !important;
  overflow: auto;
  height: 80px;
  max-width: 100%;
}

.freq-items-sec div {
  display: inline-block;
  white-space: nowrap;
}

.remove-icon {
  color: #ff0000;
}

.remove-icon * {
  cursor: pointer;
}

.inputField,
.inputField * {
  font-size: 14px !important;
}

.inputField input {
  padding: 10px 18px;
}

.service-items {
  overflow: auto;
  max-height: calc(100vh - 290px) !important;
}

.staff-icon {
  align-self: center !important;
  width: 24px;
}

textarea {
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 10px;
}

.order-comp {
  background: #f8f8f8;
  border-top: 1px solid #e3e3e3;
}

.item-comp {
  border-left: 1px solid #ccc;
  padding-left: 10px;
}

.d-inline {
  display: inline-block;
  vertical-align: top;
}

.white-bg {
  background-color: #ffffff;
}

/* Responsive */
@media only screen and (max-width: 844px) {
  body {
    padding: 0;
  }

  .bt-xs-1 {
    border-top: 1px solid #e3e3e3;
  }

  .lg-sec {
    height: auto;
  }

  .title-sec,
  .content-sec .title-sec,
  .form-sec .title-sec {
    left: 0 !important;
    width: 100% !important;
  }

  .d-flex {
    display: flex;
    border-top: 1px solid #e3e3e3;
  }

  .d-flex * {
    align-self: center;
  }

  .daterangepicker .drp-buttons .btn {
    font-size: 11px !important;
  }

  .btn-text {
    display: none;
  }

  .search-form {
    width: 100%;
    padding: 0px 10px 10px !important;
  }

  .search-form .form-control {
    min-width: auto !important;
  }

  div .refresh-icon {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 4px 2px 4px 6px;
    min-width: auto;
    text-align: center;
    justify-content: center;
    display: flex !important;
    transition: all 0.5s ease;
  }

  .visit-table {
    height: auto;
    margin-top: 10px;
  }

  .payment-filter:nth-child(2) {
    margin-left: 0;
  }

  .visit-datepicker {
    margin-top: 5px;
  }

  .visit-datepicker label {
    align-self: start !important;
  }

  .visit-datepicker span,
  .visit-datepicker input {
    font-size: 12px !important;
  }

  .visit-table tr {
    margin: 10px;
    display: block;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding-top: 5px;
  }

  .visit-table td {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 10px;
    border: 0 !important;
  }

  .visit-table td:not(:nth-child(2)) {
    border-bottom: 0 !important;
  }

  .visit-table th:nth-child(1) {
    display: block;
    margin-bottom: 10px;
    padding: 5px 10px;
  }

  .visit-table thead {
    display: none;
  }

  .menuList {
    height: auto;
    display: block;
    margin: 5px auto 0;
    position: initial;
    left: 0;
    right: 0;
  }

  .menuList ul {
    text-align: center;
  }

  .menuList ul li {
    padding: 3px 5px;
    font-size: 12px;
  }

  .mb-txt {
    display: inline-block;
  }

  .visit-table td img {
    align-self: center;
  }

  .visit-filter-sec {
    position: fixed;
    top: 100px;
    z-index: 9;
    height: 0;
    padding: 0px;
    transition: all 0.5s ease;
    overflow: hidden;
  }

  .visit-filter-check:checked + label {
    background: linear-gradient(to right, #539b00, #519603) !important;
    color: #ffffff !important;
    border-color: #539b00 !important;
  }

  .visit-filter-check:checked + label + .visit-filter-sec {
    height: 208px;
    padding: 10px;
  }

  .menu-icon {
    top: 10px !important;
    left: 10px;
    right: initial !important;
  }

  .menu-check:checked + label + .leftMenuInner {
    left: 0;
    box-shadow: 0 10px 100px rgb(0 0 0 / 50%);
  }

  .menu-check + label {
    padding: 4px !important;
  }

  .menu-check:checked + label {
    left: 260px;
    border-left: 0 !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: #8bc34a !important;
    background: #8bc34a !important;
    color: #ffffff !important;
    z-index: 9;
  }

  .menu-check + label .menuClose,
  .menu-check:checked + label .menuOpen {
    display: none;
  }

  .menu-check:checked + label .menuClose {
    display: block;
  }

  .leftMenuInner {
    left: -270px;
  }

  .title-sec h2 {
    padding: 8px 15px 11px 50px;
  }

  .mt-xs-1 {
    margin-top: 1rem;
  }

  .inputField input {
    padding: 4px 10px;
  }

  .stat-sec {
    padding-top: 32px;
  }

  .customer-chart svg {
    width: 85% !important;
  }

  .jobcard-chart svg {
    width: 65% !important;
  }

  .my-xs-1 {
    margin: 1rem 0 !important;
  }

  .xs-column {
    flex-direction: column !important;
  }

  .xs-row {
    flex-direction: row !important;
  }

  .mt-xs-3 {
    margin-top: 3rem !important;
  }

  .m-xs-0 {
    margin: 0 !important;
  }

  .m-xs-0 .card {
    margin: 3px !important;
  }

  .dialogModal .MuiDialog-paper {
    min-width: 90% !important;
  }

  .btn-secondary {
    min-width: auto !important;
    padding: 4px 6px 4px 6px !important;
  }

  .DialogContent {
    width: 100% !important;
  }

  .MuiDialog-paper {
    margin: 10px !important;
    width: 90%;
  }

  .toolbar {
    flex: 1;
    margin-bottom: 10px;
    padding: 0 10px;
  }

  .toolbar > div {
    justify-content: space-between;
  }

  .cs-modal {
    width: 95%;
  }

  .text-xs-left {
    text-align: left !important;
  }

  .text-xs-center {
    text-align: center !important;
  }

  .mb-jc-sb {
    justify-content: space-between !important;
  }

  .p-lg-2 {
    padding: 0;
  }

  .pt-xs-1 {
    padding-top: 1rem;
  }

  .vehicles-table {
    margin-top: 0px !important;
  }

  .prod-item {
    padding: 5px !important;
    margin: 0 !important;
    border-radius: 6px;
    background-color: #f4f4f4;
  }

  .prod-item * {
    font-size: 13px !important;
    padding: 0 !important;
  }

  button.remove-btn {
    padding: 10px 4px;
  }

  .left-menu {
    display: none;
    max-width: 100%;
  }

  .setting-page .left-menu {
    display: block;
  }

  .avatar-sec {
    justify-content: center;
  }

  .form-sec {
    padding: 15px;
    width: 100%;
  }

  .invoice-sec {
    padding-top: 65px;
  }

  .data-grid-box {
    overflow: auto;
  }

  .data-grid-box .data-grid {
    width: 1024px;
  }

  .headerReport {
    font-size: 134px;
  }
}

.booking-form input {
  height: 22px;
  font-size: 16px;
}

.booking-form label span {
  font-size: 14px;
}

.booking-form label {
  font-size: 14px;
  background-color: #ffffff;
  padding-right: 10px;
}

.prod-wrap {
  cursor: pointer;
}

.prod-wrap:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 844px) {
  .booking-sec {
    min-height: initial;
  }

  .img-sec img {
    width: 100%;
    height: auto;
  }

  .date-picker-sec {
    border-top: 1px solid #f4f4f4;
    width: 100%;
    padding: 6px;
    justify-content: center;
  }

  body .react-daterange-picker__wrapper {
    border-left: 0;
  }

  .c-sec2 {
    padding-top: 92px;
  }

  .xs-pd-0 {
    padding: 0 !important;
  }

  .css-payment-modal {
    width: 90%;
  }

  .payment-modal-sec h5 input {
    width: auto;
  }

  .cs-data-grid {
    width: 1000px;
  }
}

.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
}

@media only screen and (max-width: 1368px) {
  .topHeader .card a span {
    font-size: 14px !important;
  }

  .topHeader {
    margin-top: 10px !important;
  }

  .card button {
    padding: 6.5px !important;
  }
}

.address-wrap {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  position: relative;
}

.address-wrap > label {
  position: absolute;
  top: -10px;
  left: 4px;
  font-size: 11px !important;
  background: #ffffff;
  padding: 0 25px 0 6px;
  color: rgba(0, 0, 0, 0.6);
}

.MuiChip-root.MuiChip-filled {
  height: auto;
  padding: 3px 0;
  font-size: 11px;
}

.pac-target-input {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  display: inline-block;
  padding: 14px;
  width: 100%;
}

.pac-target-input-first {
  border: 1px solid rgba(255, 1, 1, 0.967);
  border-radius: 4px;
  display: inline-block;
  padding: 14px;
  width: 100%;
  outline: none;
}

.data-grid td,
.data-grid th {
  padding: 4px;
}

.popConus {
  padding: 0;
  text-align: center;
}

.split {
  height: 90vh;
  position: absolute;
  top: 0;
  transition: all 0.5s ease;
}

.left {
  width: 100%;
  left: 0;
}

.split-left {
  width: 70%; /* Adjust when split screen is active */
}

.right {
  width: 30%;
  right: 0;
}
.cs-custom {
  padding: 1cap;
  height: calc(100vh - 60px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 36%);
}
